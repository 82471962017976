<template>
    <ValidationObserver ref="obs"
                        tag="div">
        <TextField v-model="name"
                   name="name"
                   ref="name"
                   rules="required"
                   :label="$t('labels.employee_group_name')"></TextField>
        <Autocomplete :label="$t('navigation.employees')"
                      :items="responsibleEmployees"
                      name="employeesIds"
                      clearable
                      :loading="isResponsibleEmployeesLoading"
                      :search-input.sync="searchResponsibleEmployees"
                      @click:clear="getResponsibleEmployees"
                      @focus="getResponsibleEmployees"
                      :multiple="true"
                      @load-more-items="getResponsibleEmployeesLazyLoading"
                      v-model="employeesIds"></Autocomplete>
    </ValidationObserver>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { ValidationObserver } from 'vee-validate';
import TextField from '@/components/widgets/forms/TextField';
import Autocomplete from '@/components/widgets/forms/Autocomplete';
import debouncedResponsibleEmployee from '@/mixins/debounced/debouncedResponsibleEmployee';

export default {
    name: 'ManageEmployeesGroupForm',
    mixins: [debouncedResponsibleEmployee],
    components: { ValidationObserver, TextField, Autocomplete },
    computed: {
        ...mapFields('manageEmployeesGroup', [
            'groupData.name',
            'groupData.employeesIds'
        ]),
        ...mapState('manageEmployeesGroup', [
            'groupFormVisibility'
        ]),
        ...mapGetters('employees', [
            'responsibleEmployees'
        ]),
        isFormDirty () {
            return this.$refs.obs.flags.changed;
        }
    },
    watch: {
        groupFormVisibility: {
            handler (val) {
                if (val) {
                    const params = {};
                    if (this.employeesIds.length > 0) {
                        params['filter[id]'] = this.employeesIds.join(',');
                    }
                    this.getResponsibleEmployees(params);
                }
            },
            immediate: true
        }
    },
    methods: {
        resetValidation () {
            return this.$refs.obs.reset();
        },
        validate () {
            return this.$refs.obs.validate();
        }
    }
};
</script>

<style scoped>

</style>
